<template>
  <div id="AddUpdateOrganization">
    <v-dialog max-width="600" persistent v-model="saveOrganizationDialog">
      <v-card class="pa-5">
        <v-card-title>Save Organization</v-card-title>
        <v-card-text class="mt-5">
          <v-form ref="form">
            <v-row>
              <v-col class="py-1" cols="12">
                <v-text-field outlined :rules="[rules.nameRequired]" v-model="organizationName"
                              label="Organization Name*"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" class="error" depressed>CANCEL</v-btn>
          <v-btn @click="saveOrganization" class="primary" depressed>SAVE</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddUpdateOrganization',
  props: {
    organization: { type: [Object], default: null },
    saveOrganizationDialog: { type: [Boolean], default: false },
  },
  data () {
    return {
      organizationName: null,
      rules: {
        nameRequired: value => !!value || 'Name Required.',
      },
    }
  },
  mounted () {
    if (this.organization)
      this.organizationName = this.organization.name
  },
  methods: {
    validate () {
      if (this.$refs.form.validate())
        this.saveOrganization()
    },
    async saveOrganization () {

    },
    cancel () {
      this.$emit('cancel')
    },
  },
}
</script>

<style scoped>

</style>
