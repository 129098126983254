var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" Organizations ("+_vm._s(_vm.organizations.length)+") "),_c('v-spacer'),_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.addUpdateOrganization(null)}}},[_vm._v("Add Organization")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"show-expand":"","loading":!_vm.organizations.length,"items-per-page":5,"headers":_vm.organizationHeaders,"no-data-text":"No Organization Available","no-results-text":"No Organization Available","items":_vm.organizations,"item-key":"id"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.totalInstance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.instances.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","rounded":"","small":"","color":"primary"},on:{"click":function($event){return _vm.addUpdateInstance(item)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{attrs:{"icon":"","rounded":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addUpdateOrganization(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return _vm._l((item.instances),function(instance,index){return _c('div',{key:index},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(_vm._s(instance.instance_name))]),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(_vm._s(instance.current_number))]),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(_vm._s(instance.instance_data.port))]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","x-small":"","small":"","color":"primary"},on:{"click":function($event){return _vm.addUpdateInstance(instance)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)],1)],1)})}}])})],1)],1)],1)],1),(_vm.saveOrganizationDialog)?_c('AddUpdateOrganization',{attrs:{"organization":_vm.organization,"saveOrganizationDialog":_vm.saveOrganizationDialog},on:{"saveOrganization":_vm.saveOrganization,"showToast":_vm.showToaster,"cancel":function($event){_vm.saveOrganizationDialog=false}}}):_vm._e(),(_vm.saveInstanceDialog)?_c('AddUpdateInstance',{attrs:{"organization":_vm.organization,"saveInstanceDialog":_vm.saveInstanceDialog},on:{"saveInstance":_vm.saveInstance,"cancel":function($event){_vm.saveInstanceDialog=false},"showToast":_vm.showToaster}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }