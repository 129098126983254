<template>
  <v-container fluid>
    <v-row class="text-center pt-5">
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            Organizations ({{ organizations.length }})
            <v-spacer></v-spacer>
            <v-btn @click="addUpdateOrganization(null)" class="primary">Add Organization</v-btn>
          </v-card-title>
          <v-card-text>
            <v-data-table show-expand :loading="!organizations.length" :items-per-page="5" class="elevation-1"
                          :headers="organizationHeaders" no-data-text="No Organization Available"
                          no-results-text="No Organization Available" :items="organizations" item-key="id">
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item.totalInstance="{ item }">
                {{ item.instances.length }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn @click="addUpdateInstance(item)" icon rounded small color="primary">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn @click="addUpdateOrganization(item)" icon rounded x-small color="primary">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <div v-for="(instance,index) in item.instances" :key="index">
                  <v-row>
                    <v-col cols="auto">{{ instance.instance_name }}</v-col>
                    <v-col cols="auto">{{ instance.current_number }}</v-col>
                    <v-col cols="auto">{{ instance.instance_data.port }}</v-col>
                    <v-col cols="auto">
                      <v-btn @click="addUpdateInstance(instance)" icon x-small small color="primary">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <AddUpdateOrganization @saveOrganization="saveOrganization" :organization="organization" @showToast="showToaster"
                           @cancel="saveOrganizationDialog=false" :saveOrganizationDialog="saveOrganizationDialog"
                           v-if="saveOrganizationDialog"></AddUpdateOrganization>
    <AddUpdateInstance @saveInstance="saveInstance" :organization="organization" @cancel="saveInstanceDialog=false"
                       :saveInstanceDialog="saveInstanceDialog" @showToast="showToaster"
                       v-if="saveInstanceDialog"></AddUpdateInstance>
  </v-container>
</template>

<script>
import axios from 'axios'
import AddUpdateInstance from '@/components/AddUpdateInstance'
import AddUpdateOrganization from '@/components/AddUpdateOrganization'

export default {
  name: 'Home',
  components: { AddUpdateOrganization, AddUpdateInstance },
  props: {
    url: { type: [String], default: null },
  },
  data () {
    return {
      organizations: [], organization: null,
      saveInstanceDialog: false, saveOrganizationDialog: false,
      organizationHeaders: [
        { text: 'Organization', value: 'name' },
        { text: 'Total Instances', value: 'totalInstance' },
        { text: 'Actions', value: 'actions' },
      ],
      instanceHeaders: [
        { text: 'Name', value: 'instance_name' },
        { text: 'Mobile', value: 'current_number' },
        { text: 'Port', value: 'instance_data.port' },
        { text: 'Actions', value: 'instance_actions' },
      ],
    }
  },
  mounted () {
    setTimeout(() => {
      this.organizations = this.$store.getters.organizations
    }, 1000)
    this.$forceUpdate()
  },
  methods: {
    addUpdateInstance (obj) {
      this.organization = JSON.parse(JSON.stringify(obj))
      this.saveInstanceDialog = true
    },
    addUpdateOrganization (obj) {
      console.log(obj)
      if (obj)
        this.organization = JSON.parse(JSON.stringify(obj))
      else
        this.organization = null
      this.saveOrganizationDialog = true
    },
    saveInstance (obj) { console.log(obj) },
    saveOrganization (obj) { console.log(obj) },
    sendMessage () {
      axios.post(`${this.url}sendMessage`, { to: '918980581991', messageType: 'TEXT', content: this.message }).
          catch(err => {console.log(err)})
    },
    showToaster () {},
  },
}
</script>
